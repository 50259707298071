import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Domain/Helpers/HelperLang";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN 
});

// Crear un ErrorBoundary global para envolver la aplicación
const ErrorBoundary = Sentry.withErrorBoundary(App, {
  fallback: (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Oops! Algo salió mal.</h1>
      <p>Por favor, intenta recargar la página o contacta al soporte.</p>
    </div>
  ),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary />
  </React.StrictMode>
);

// Medir el rendimiento de la aplicación
reportWebVitals();
